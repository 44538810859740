export const App = {
  pageLoad: [],
  pageResize: [],
  pageScroll: [],
  tearDown: [],
  runFunctions: function(array){
    for(var i = 0; i < array.length; i++) {
      array[i];
    }
  },
};