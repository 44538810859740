import $ from "jquery";

// toggle subfooter
$('.upper-footer a').not('.artnet').click(function(e) {
  e.preventDefault();
  $('.lower-footer').toggleClass('show');
});

$('.navbar').on('hidden.bs.collapse', function () {
  $('.lower-footer').removeClass('show');
  $('body').removeClass('no-scroll');
});

$('.navbar').on('shown.bs.collapse', function () {
  $('body').addClass('no-scroll');
});
