import $ from "jquery";
import { updatePage, updateNavLoader } from "./nav-helpers.js";
import { initializeGalleries, initializeSplash } from "./gallery.js";

/*---------------------------------------------------
  Set up infinite scrolll
---------------------------------------------------*/
let firstChapter = $('.chapter-container.initial');
var chapters = firstChapter.length > 0 ? [firstChapter] : [];
var stateLoading = false;
var $splashContainer = $('#chapter-splash')

export function infiniteScroll() {

  $( document ).ready(function() {

    initInfiniteLoad();

    /*---------------------------------------------------
      Initialize infinite loader listeners
    ---------------------------------------------------*/
    function initInfiniteLoad(){
      var lastScrollTop = 0;

      window.addEventListener("scroll", function(){
        if(!stateLoading){
          var st = window.pageYOffset || document.documentElement.scrollTop;
          // Scroll Down
          if (st > lastScrollTop){
            setChapterViewed('next'); // update history if a new chapter is in view

            if($(window).scrollTop() + $(window).height() >= $(document).height() - 200){
              let lastChapter = chapters[chapters.length - 1];
              let next = lastChapter.find('.next-chapter').data();
              loadNewChapter(lastChapter, next);
            }
            // Close navigation
            $('.navbar-collapse').collapse('hide');

          } else { // Scroll up
            setChapterViewed('previous'); // update history if a new chapter is in view
            // Close navigation
            $('.navbar-collapse').collapse('hide');
            // TODO: load previous chapters that may have been removed from the DOM
          }
          splashTransition();
          lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
        }
      }, false);
    }

    function splashTransition(){
      if ($splashContainer.length > 0){
        let offset = $('.navbar').outerHeight();
        let bottom = $splashContainer[0].getBoundingClientRect().bottom;
        let percentage = bottom < 0 ? 0 : (bottom - offset) / ($(window).height() - offset);
        $splashContainer.find('.inner-splash-wrapper').css('opacity', percentage);
      }
    }

    /*---------------------------------------------------
      Load in a new chapter to the bottom of the page
    ---------------------------------------------------*/
    function loadNewChapter(lastChapter, next){
      stateLoading = true;
      var $newContainer = $('<div id=chapter-'+next.id+' class="chapter-container"></div>')
      $newContainer.insertAfter(lastChapter);


      $newContainer.load( next.url, function() {
        stateLoading = false;
        chapters.push($newContainer)

        initializeGalleries();
        initializeSplash();

        // keep the DOM small
        if (chapters.length > 12){
          let heightOffset = chapters[0].outerHeight();
          let scrollPosition = $(window).scrollTop();
          chapters[0].remove();
          $(window).scrollTop(scrollPosition - heightOffset);
          chapters.shift();
        }
      });
    }

    /*---------------------------------------------------------------
      Determine which chapter is in view by calculating which
      chapter top is closest to the threshold without being below it
    -----------------------------------------------------------------*/
    function setChapterViewed(direction){
      // let threshold = $(window).height() * .30; // 30% from the top
      let threshold = $('.navbar').outerHeight();
      let $chapterViewed, chapterViewedPosition;
      $chapterViewed = chapters[0];
      chapterViewedPosition = chapters[0][0].getBoundingClientRect().top;

      for (var x = 1; x < chapters.length; x++){ // no need to start at 0 since we initialize
        let chapterTop = chapters[x][0].getBoundingClientRect().top;

        if (chapterTop < threshold && chapterTop > chapterViewedPosition){
          $chapterViewed = chapters[x];
          chapterViewedPosition = chapterTop;
        }
      }

      let $currentElem = $chapterViewed.find('.current-chapter')
      if ($currentElem.length > 0){
        let current = $currentElem.data();
        updatePage(current.url, current.id, direction);

        let bottom = $chapterViewed[0].getBoundingClientRect().bottom;
        let bottomDiff = bottom - $(window).height()
        let percentage = bottomDiff > 0 ? bottomDiff / $chapterViewed.height() : 0
        percentage = 100 - Math.floor(percentage * 100)
        updateNavLoader(percentage)
      }
    }
  });
}

/*---------------------------------------------------
  Force load a page when using the back button
---------------------------------------------------*/
$(window).bind('popstate', function(event){
  window.location.href = location.href;
});

/*---------------------------------------------------
  Animate page load when clicking on splash page
---------------------------------------------------*/
function initSplashClick(){
  $splashContainer.find('a').click(function(e){
    e.preventDefault();
    animatePageLoad($(this));
  });
}

/*---------------------------------------------------
  When clicking on a navigation link
  load that page via ajax
---------------------------------------------------*/
export function linkLoader() {
  $( document ).ready(function() {

    initSplashClick();

    $('.nav-link.chapter').click(function(e){
      e.preventDefault();
      $('.navbar-toggler').click();
      animatePageLoad($(this));
    });

    $('.thrity-years a').click(function(e){
      e.preventDefault();
      if ($('#chapter-splash').length > 0){
        // window.scrollTo(0,0) <- if you dont want to animate
        $('html, body').animate({
          scrollTop: 0
        }, 1000);
      } else {
        animatePageLoad($(this))
        $('body').removeClass('chapter');
        $('body').addClass('splash');
      }
    });
  });
}

function animatePageLoad($link){
  // The speed of this animation is controlled by the css
  // in the mixins file
  let $chapters = $('.chapter-container');

  $chapters.addClass('fadeOut'); // fade out the visible chapter
  setTimeout(function() {
    $chapters.remove();
    // then load in the selected one
    var $newContainer = $('<div id=chapter-'+$link.data('id')+' class="chapter-container initial fadedOut"></div>')
    $newContainer.insertAfter('.navbar');
    $newContainer.load( $link.attr('href'), function() {
      updatePage($link.attr('href'), $link.data('id'), "link");
      chapters = [$newContainer];
      $newContainer.addClass('fadeIn');
      $newContainer.removeClass('fadedOut');

      $splashContainer = $('#chapter-splash')
      initSplashClick();
    });
  }, 1000);
}
