import $ from "jquery"
import { initializeModals } from "./gallery.js"

export function initializeModalListeners() {
  let selectIndex = 0;

  $( document ).ready(function() {
    $('#modal').on('show.bs.modal', function (event) {
      const figure = $(event.relatedTarget);

      let wrapperClass = '';
      let $imagesForModal;

      if (figure.data('type') == 'textandimage'){
        $imagesForModal = figure.siblings('.images-for-modal')
        selectIndex = figure.data('index');
        wrapperClass ='flickity--modal'
      } else if(figure.data('type') == 'image'){
        $imagesForModal = figure.siblings('.images-for-modal')
        wrapperClass ='single-image--modal'
      } else if(figure.data('type') == 'gallery') {
        $imagesForModal = figure.closest('.flickity').find('.images-for-modal')
        selectIndex = figure.data('index');
        wrapperClass ='flickity--modal'
      }
      if($imagesForModal){
        const $images = $imagesForModal.clone();
        let $contentForModal = $('<div class="'+wrapperClass+'"></div>');
        $contentForModal.append($images);
        $(this).find('.modal-body').append($contentForModal)
      }

    })

    $('#modal').on('shown.bs.modal', function (event) {
      var figure = $(event.relatedTarget);

      if (figure.data('type') == 'textandimage' || figure.data('type') == 'gallery'){
        initializeModals(selectIndex)
      }

      $('.images-for-modal', this).addClass('images-for-modal--loaded')
    })

    $('#modal').on('hidden.bs.modal', function (e) {
      $('.modal-body', this).empty();
    })

  })
}
