import Flickity from 'flickity'
import FlickityFade from 'flickity-fade'

export function initializeGalleries(){
  var flktyElements = document.querySelectorAll('.flickity');

  for (var x = 0; x < flktyElements.length; x++){
    var flkty = new Flickity( flktyElements[x], {
      cellAlign: "center",
      contain: true,
      pageDots: false,
      setGallerySize: false,
      percentPosition: true,
      wrapAround: true
    });

    var caption = flktyElements[x].parentNode.querySelector('.caption');
    if (caption){
      caption.innerHTML = flkty.selectedElement.querySelector('.carousel-caption').innerHTML;
    }

    flkty.on( 'select', function() {
      if (caption && flkty && flkty.selectedElement){
        caption.innerHTML = flkty.selectedElement.querySelector('.carousel-caption').innerHTML;
      }
    });

  }
}

export function initializeModals(selectIndex){
  var flktyElements = document.querySelectorAll('.modal .flickity--modal');

  for (var x = 0; x < flktyElements.length; x++){
    var flkty = new Flickity( flktyElements[x], {
      cellAlign: "center",
      contain: true,
      pageDots: false,
      wrapAround: true,
      setGallerySize: false,
      percentPosition: true
    });
    flkty.select(selectIndex, false, true);
  }
}

export function initializeSplash(){
  var flktyElements = document.querySelectorAll('.slideshow');

  for (var x = 0; x < flktyElements.length; x++){
    var flkty = new Flickity( flktyElements[x], {
      pageDots: false,
      lazyLoad: 1,
      autoPlay: 2000,
      fade: true,
      draggable: false,
      prevNextButtons: false,
      pauseAutoPlayOnHover: false,
      adaptiveHeight: false,
      setGallerySize: false,
      wrapAround: true
    });

  }
}
