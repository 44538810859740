import 'jquery'

// Import select parts of bootstrap as needed

// import 'bootstrap/js/dist/alert'
// import 'bootstrap/js/dist/button'
// import 'bootstrap/js/dist/carousel'
import 'bootstrap/js/dist/collapse'
// import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/modal'
// import 'bootstrap/js/dist/popover'
// import 'bootstrap/js/dist/scrollspy'
// import 'bootstrap/js/dist/tab'
// import 'bootstrap/js/dist/toast'
// import 'bootstrap/js/dist/tooltip'

// import 'lazysizes'
// import 'lozad'
import 'vanilla-lazyload'

// Project-specific code
import { App } from "./js/app.js";
import { lazyload } from "./js/lazyload.js";
import { initializeGalleries, initializeSplash } from "./js/gallery.js";
import { initializeModalListeners } from "./js/modal.js";
import { infiniteScroll, linkLoader } from "./js/infinite-scroll.js";
import { navFooter } from "./js/nav-footer.js";

App.pageLoad.push(initializeGalleries());
App.pageLoad.push(infiniteScroll());
App.pageLoad.push(initializeSplash());
App.pageLoad.push(initializeModalListeners());
App.pageLoad.push(linkLoader());

App.runFunctions(App.pageLoad);
