import $ from "jquery";

import LazyLoad from "vanilla-lazyload";
var myLazyLoad = new LazyLoad();
// After your content has changed...

// Update the history and nav with a new chapter
// Assumes newUrl is full path including domain name
export function updatePage(newUrl, id, direction){
  let currentUrl = window.location.href
  // If current url does not equal new url then proceed updating the history
  // .replace(/\/+$/g, '') gets rid of the optional trailing slash
  if( currentUrl.replace(/\/+$/g, '') !== newUrl.replace(/\/+$/g, '') ){
    if (direction == "link"){
      history.pushState({}, id, newUrl );
    } else {
      history.replaceState({}, id, newUrl);
    }
    if (id !== 'splash'){
      $('.nav-item.active').removeClass('active')
      $('#nav-'+id).addClass('active')
      updateNav(id)
      let title = document.title.split(' | ')
      document.title = title[0] + ' | ' + id
      $('body').removeClass('splash');
      $('body').addClass('chapter');
      myLazyLoad.update();
    } else {
      $('.nav-item.active').removeClass('active')
      $('body').removeClass('chapter');
      $('body').addClass('splash');
    }
  }
}

function updateNav(chapterTitle){
  $('.chapter-indicator').html(chapterTitle);
  $('.chapter-indicator').addClass('transition');
  $('.chapter-indicator').removeClass('fade');
  setTimeout(function() {
    $('.chapter-indicator').addClass('fade');
    $('.chapter-indicator').removeClass('transition');
  }, 100);
}

export function updateNavLoader(percentage){
  document.querySelector('.circle').dataset.fill = percentage
}
